import React from "react";

import Layout from "../../components/layout";

const pageTitle = "Privacy Policy";

const PrivacyPolicyPage = () => (
    <Layout pageTitle={pageTitle}>
        <h4>Privacy Policy</h4>

        <p>
            We respect your privacy and are committed to protecting your personal information. This policy outlines how
            we
            collect, use, and safeguard your data.
        </p>

        <h5>Information We Collect</h5>
        <ul>
            <li>Personal details (such as name, email, phone number) when you interact with us.</li>
            <li>Usage data when you visit our website.</li>
        </ul>

        <h5>How We Use Your Information</h5>
        <ul>
            <li>To provide and improve our services.</li>
            <li>To communicate with you about your inquiries or purchases.</li>
            <li>To comply with legal requirements.</li>
        </ul>

        <h5>Data Protection</h5>
        <p>
            We implement security measures to protect your personal information from unauthorized access or
            disclosure.
        </p>

        <h5>Third-Party Sharing</h5>
        <p>
            We do not sell or share your personal information with third parties, except as required by law.
        </p>

        <h5>Your Rights</h5>
        <p>
            You may request access, correction, or deletion of your personal information by contacting us.
        </p>

        <h5>Contact Us</h5>
        <p>
            If you have any questions, please reach out to us at {' '}
            <a href="mailto:support@sqlapi.com">support@sqlapi.com</a>.
        </p>
    </Layout>
);

export default PrivacyPolicyPage;
